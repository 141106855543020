.home__inner-content {
  width: 650px;
  margin: auto;
}

.home__heading {
  display: flex;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.home-category-list {
  list-style: none;
  width: fit-content;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  width: 100%;
}

.home-category-list__item {
  padding: 1rem;
  color: white;
  cursor: pointer;
  border-radius: 8px;
  opacity: 0.95;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.home-category-list__item:hover {
  opacity: 1;
}

.home-category-list__image-wrapper {
  display: flex;
  justify-content: flex-end;
}

.home-category-list__icon {
  display: flex;
  font-size: 1.5rem;
}

.home-category-list__heading {
  font-weight: 600;
  display: flex;
  font-size: 1.25rem;
}

.home-category-list__item--xreplay {
  background-color: #004ac4;
}

.home-category-list__item--youtube {
  background-color: #ff0033;
}

.home-category-list__item--instagram {
  background-color: #ec0075;
}

.home-category-list__item--facebook {
  background-color: #4f5bd5;
}

.home-category-list__item--elite-program {
  background-color: var(--clr_accent);
}

.home-category-list__type {
  font-size: 0.85rem;
}

@media all and (max-width: 700px) {
  .home__inner-content {
    width: 100%;
    padding: 1rem;
  }

  .home__heading {
    font-size: 1.2rem;
  }
}

@media all and (max-width: 550px) {
  .home-category-list {
    grid-template-columns: repeat(1, 1fr);
  }
}
