.custom__button {
  width: 100%;
  padding: 16px;
  border: none;
  border-radius: 4px;
  font-weight: 700;
  font-size: var(--18px);
  color: black;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: var(--clr_accent);
}

button.custom__button:hover {
  cursor: pointer;
  transition: all ease-in-out;
  background-color: var(--clr-button-hover);
}

button.contained {
  background-color: var(--clr_accent);
}

button.outlined {
  border: solid 2px var(--clr_accent) !important;
  background-color: transparent !important;
}

.disabled-button {
  background-color: grey !important;
}
