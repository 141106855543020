div.c_text-field__container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

label.c_text-field__label {
  line-height: 1.5;
  color: white;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  opacity: 0.6;
}

input.c_text-field__input {
  color: black;
  padding: 12px;
  border-radius: 4px;
  border: solid 1px silver;
  outline-style: none;
  width: 100%;
}

input[type="date"],
input[type="time"] {
  height: 44px;
}

div.custom-text-field_input__wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;
}

button.custom-text-field__show-password-btn {
  position: absolute;
  right: 16px;
}

div.medium {
  width: 50%;
}

div.small {
  width: 25%;
}

@media all and (max-width: 600px) {
  div.medium {
    width: 100%;
  }
}
