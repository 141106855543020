.header {
  width: 100%;
  height: fit-content;
  background-color: transparent;
}

.header > .navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  width: 100%;
  padding: 0 24px 0 24px;
  background-color: transparent;
}

.header_logo__wrapper {
  width: 24px;
  height: 24px;
}

.header_logo__wrapper > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.navbar-menu {
  list-style: none;
  margin-left: 2rem;
}

.navbar-menu__item:hover {
  cursor: pointer;
  text-decoration: underline;
}

@media all and (max-width: 650px) {
  .header > .navbar {
    padding: 0 16px 0 16px;
  }
}
