@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --clr_primary: #00111a;
  --clr_secondary: #5cb3ff; /*30%*/
  --clr_accent: #5dbc0c; /*10%*/

  --clr-button-hover: #65af26;
  --clr-dropmenu-hover: #f3f3f3;

  /* Font sizes: */
  --40px: 2.5rem;
  --32px: 2rem;
  --24px: 1.5rem;
  --20px: 1.25rem;
  --18px: 1.125rem;
  --16px: 1rem;
  --14px: 0.875rem;
  --13px: 0.813rem;
  --12px: 0.75rem;
  --10px: 0.625rem;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  overscroll-behavior: none;
  color: white;
  overflow-y: scroll;
  background-color: var(--clr_primary);
}

textarea,
input,
label {
  font-family: inherit;
}

button {
  color: black;
}
