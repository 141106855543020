.elite-program__inner-content {
  width: 700px;
  margin: 0 auto;
}

.elite-program__heading {
  font-size: 1.5rem;
  font-weight: 600;
}

.elite-program__phase {
  display: flex;
  margin-top: 2rem;
}

.elite-program-card-header {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.elite-program-card-header__day {
  font-size: 1.25rem;
  font-weight: 600;
}
.elite-program-card-heading__session-type {
  margin-top: 1rem;
  font-size: 1.25rem;
}

.elite-program-card-header__time {
  font-size: 0.85rem;
  color: black;
  font-weight: 600;
  width: fit-content;
  border-radius: 1rem;
  padding: 0.15rem 1rem 0.15rem 1rem;
  background-color: var(--clr_accent);
  margin-top: 0.5rem;
}

.elite-program-card__detail {
  padding: 1rem;
  color: black;
  border-radius: 8px;
  margin: 1rem 0 1rem 0;
  background-color: white;
}

.elite-program-card__title {
  text-decoration: underline;
  font-style: italic;
}

.elite-program-card__warm-up {
  margin-bottom: 1rem;
}

.elite-program-card__warm-down {
  margin-top: 1rem;
}

.elite-program-card__exercise {
  font-weight: 400;
  display: flex;
  justify-content: space-between;
}

.elite-program-card__action {
  font-weight: 600;
}

.elite-program-card__exercise--warm-down {
  margin-top: 1rem;
}

.elite-program-card__type-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.elite-program-card__table-header {
  display: flex;
  height: fit-content;
  width: fit-content;
  text-align: end;
  justify-content: flex-end;
}

.elite-program-card__tag {
  display: flex;
  width: 120px;
  font-weight: 500;
  text-align: center;
  justify-content: center;
}

.elite-program-card__tag:last-child {
  display: flex;
  text-align: right;
  justify-content: flex-end;
}

.elite-program__buttons {
  display: flex;
  gap: 0.5rem;
}

.elite-program__button {
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  text-align: center;
  cursor: pointer;
  opacity: 0.9;
  font-weight: 600;
}

.elite-program__button:hover {
  opacity: 1;
}

.elite-program__button--phases {
  flex-grow: 0;
}

.elite-program__button--days {
  flex-grow: 1;
}

.elite-program__select-label {
  display: flex;
  margin: 1rem 0 0.5rem 0;
  font-size: 0.85rem;
}

.elite-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.2;
  background-color: black;
}

.elite-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.5;
  background-color: black;
}

.elite-overlay__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media all and (max-width: 750px) {
  .elite-program__inner-content {
    width: 100%;
  }

  .elite-program__buttons--days {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .elite-program__button--days {
    flex-grow: 0;
  }

  .elite-program-card__tag {
    display: flex;
    width: 55px;
    font-weight: 500;
    text-align: center;
    justify-content: center;
  }

  .elite-program-card__action {
    text-align: right;
  }
  .elite-program-card__detail {
    font-size: 0.85rem;
  }
}
