.eoi-v__form {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 550px;
  padding: 1rem;
}

.form__wrapper--col {
  display: flex;
  flex-direction: column;
}

.form__wrapper--row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.form__wrapper--select-options {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.eoi-v__select {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.eoi-v__select--label {
  font-size: 0.875rem;
  font-weight: 600;
  opacity: 0.6;
}

.eoi-v__select--option {
  border: solid 2px white;
  border-radius: 25px;
  color: white;
  width: fit-content;
  padding: 0 1rem 0 1rem;
  height: 30px;
  line-height: 1;
  margin-top: 0.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
}

.eoi-v__select--option:hover {
  cursor: pointer;
  border: solid 2px var(--clr_accent);
  background-color: var(--clr_accent);
}

.active-button {
  border: solid 2px var(--clr_accent);
  background-color: var(--clr_accent);
}

.eoi-v__wrapper--message-dialog {
  width: 550px;
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
  padding: 1rem;
}

.eoi-v__message-dialog--title {
  color: var(--clr_accent);
}

@media all and (max-width: 600px) {
  .eoi-v__form,
  .eoi-v__wrapper--message-dialog {
    width: 100%;
  }
}
