div.c_textarea__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 48px;
}

label.c_textarea__label {
  line-height: 1.5;
  color: white;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  opacity: 0.6;
}

textarea.c_textarea__input {
  color: black;
  padding: 12px;
  border-radius: 4px;
  border: solid 1px silver;
  outline-style: none;
  width: 100%;
  overflow: hidden;
}

span.custom-textarea-char-count {
  font-size: var(--12px);
  color: gray;
  display: flex;
  justify-content: flex-end;
  margin-top: 4px;
}
