.main-container {
  width: 100%;
  height: calc(100vh - 60px);
}

.outter-container {
  width: 100%;
  height: 100%;
}

.inner-container {
  height: 100%;
  margin: 0 auto;
}

@media all and (max-width: 1200px) {
  .inner-container {
    width: 100%;
    padding: 1rem;
  }
}
